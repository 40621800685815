<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4><i class="fal fa-users"></i> All Well Site Supervisors</h4>
          <hr>
          <v-client-table :columns="columns" :data="wssData" :options="options" :theme="theme" id="dataTable">
            <!-- <div slot="status" slot-scope="props">
              <span v-if="props.row.status === 0">
                <button class="btn btn-success w-100">Active</button>
              </span>
              <span v-if="props.row.status !== 0">
                <button class="btn btn-danger w-100">Inactive</button>
              </span>
            </div> -->
            <span slot="name" slot-scope="props">{{props.row.fname}} {{props.row.lname}}</span>

            <!-- <div slot="assignments" slot-scope="props">
              <b-button v-b-modal.assignmentsWts  class="btn-sm" variant="success" @click="findAssignments(props.row.wtsid)">View Assignments</b-button>
            </div> -->
            <div slot="actions" slot-scope="props">
                <b-button variant="primary"  class="btn-sm" v-b-modal.assignWts @click="sendInfo(props.row.wssid)">Assign To Project</b-button>
            </div>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal @shown="clearErrors" id="assignWts" ref="assignModal" title="Assign WSS To Project" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="assignvalidateBeforeSubmit" class="pb-2">
        <p class="text-muted">Select Project</p>
        <b-input-group class="mb-2">
          <b-input-group-prepend style="z-index: 99;">
            <b-input-group-text>
              <i class="fa fa-cog"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <select class="select-account form-control" name="projectid" v-model="projectid">
            <option value="" disabled selected>Select Project</option>
            <option for v-for="option in projectData" v-bind:value="option.projectid">{{option.projectname}}</option>
          </select>
        </b-input-group>

        <i v-show="assignerror" class="fa fa-exclamation-triangle"></i><span v-show="assignerror" class="help is-danger">Assign Error. Cannot have duplicates. Please contact admin.</span></span>
        <b-button variant="success" class="mt-4" type="submit" block>Create Assignment</b-button>
      </form>
    </b-modal>

    <b-modal @shown="clearErrors" id="assignmentsWts" ref="assignmentmodal" title="WSS Assignments" title-tag="h2" centered size="lg" hide-footer>
    <!-- <b-col md="6" class="my-1">
        <b-form-group horizontal label="Filter" class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search" />
            <b-input-group-append>
              <b-btn :disabled="!filter" @click="filter = ''">Clear</b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col> -->
      <!-- Main table element -->
      <!-- <b-table show-empty
               :items="wtsFilteredData"
               :fields="fields"
               :current-page="currentPage"
               @filtered="onFiltered"
               :per-page="2"
      >
      <template slot="edit" slot-scope="row">

      </template>

    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination :total-rows="wtsFilteredData.length" :per-page="2" v-model="1" class="my-0" />
      </b-col>
    </b-row> -->

      <table class="table">
        <thead>
          <th>Project Name</th>
          <th>Status</th>
          <th>Edit</th>
        </thead>
        <tr v-for="assignment in wtsFilteredData">
          <td>{{assignment.projectname}}</td>
          <td>
            <span v-if="assignment.status === 0">
              <button class="btn btn-sm btn-success w-100">Active</button>
            </span>
            <span v-if="assignment.status !== 0">
              <button class="btn btn-sm btn-danger w-100">Inactive</button>
            </span>
          </td>
          <td>
            <b-button class="btn-sm" v-b-modal.editassignmentWts @click="sendEditInfo(assignment.wpid)">Edit <i class="fa fa-edit"></i></b-button>
          </td>
        </tr>
      </table>
    </b-modal>
    <b-modal @shown="clearErrors" id="editassignmentWts" ref="editassignmentmodal" title="Edit WTS Assignment" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="editassignmentvalidateBeforeSubmit" class="pb-2">
        <p class="text-muted">Select Project</p>
        <b-input-group class="mb-2">
          <b-input-group-prepend style="z-index: 99;">
            <b-input-group-text>
              <i class="fa fa-cog"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <select class="select-account form-control" name="editprojectid" v-model="editprojectid">
            <option value="" disabled selected>Select Project</option>
            <option for v-for="option in projectData"  v-bind:value="option.projectid">{{option.projectname}}</option>
          </select>
        </b-input-group>

        <b-input-group class="mt-3 mb-2">
          <b-form-checkbox class="ml-2 mt-1" v-model="editstatus" value="0" unchecked-value="1"><p class="ml-3 mb-0"><span v-if="editstatus == 0">Active</span><span v-if="editstatus != 0"> Inactive</span></p></b-form-checkbox>
        </b-input-group>

        <i v-show="editassignerror" class="fa fa-exclamation-triangle mt-3"></i><span v-show="editassignerror" class="help is-danger">Assign Error. Cannot have duplicate assignments.</span></span>
        <b-button variant="success" class="mt-4" type="submit" block>Edit Assignment</b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
Vue.use(ClientTable)

export default {
  name: 'AllWells',
  components: {
    ClientTable,
    Event,
  },
  data () {
    return {
      columns: ['email', 'name', 'actions'],
      wssData: [],
      options: {
        headings: {
          companyid: 'Company ID',
          name: 'Company Name',
          name: 'Name',
        },
        sortable: ['companyid', 'email', 'name'],
        filterable: ['companyid', 'email', 'name'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      fname: "",
      lname: "",
      email: "",
      status: "",
      fax: "",
      phone: "",
      password: "",
      password2: "",
      id: "",
      error: false,
      passworderror: false,
      projectData: [],
      projectid: '',
      unique: false,
      assignerror: false,
      passlimit: false,
      wtsAssignmentData: [],
      wtsFilteredData: [],
      editprojectid: "",
      editwssid: "",
      editstatus: "",
      editpasslimit: "",
      edit: "",
      editid: "",
      editassignerror: false,
      fields: [
        {
          key: 'projectname',
          sortable: true
        },
        {
          key: 'status',
          sortable: true
        },
        {
          key: 'edit',
          sortable: false
        }
      ]
    }
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.id,
            fname: this.fname,
            lname: this.lname,
            email: this.email,
            status: this.status,
            fax: this.fax,
            phone: this.phone,
          })
          this.$http.post('/wts/create/wts', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Project Manager Updated Successfully', {type: 'success', duration: '3000'})
              this.$refs.editModal.hide();
              this.loadData();
            }
          })
          .catch(error => {
            if(error.response.data.details == "email_UNIQUE must be unique"){
              this.unique = true;
            }
            this.$toasted.show('Error', {type: 'error', duration: '3000'})
            this.error = true;
          })
          return;
        }
      });
    },
    passwordvalidateBeforeSubmit() {
      let data = JSON.stringify({
        id: this.id,
        password: this.password,
        confirm_password: this.password2,
      })
      this.$http.post('/manager/edit/wts_password', data)
      .then(response => {
        if (response.status === 200) {
          this.$toasted.show('Password Updated', {icon: 'fa-check', type: 'success', duration: '3000'})
          this.$refs.editPasswordModal.hide();
        }
      })
      .catch(error => {
        this.passworderror = true;
      })
      return;
    },
    assignvalidateBeforeSubmit() {
      let data = JSON.stringify({
        projectid: this.projectid,
        wssid: this.id,
      })
      this.$http.post('/wts/create/wss_project', data)
      .then(response => {
        if (response.status === 200) {
          this.$toasted.show('Assignment Created', {icon: 'fa-check', type: 'success', duration: '3000'})
          this.$refs.assignModal.hide();
        }
      })
      .catch(error => {
        this.assignerror = true;
      })
      return;
    },
    editassignmentvalidateBeforeSubmit() {
      let data = JSON.stringify({
        id: this.editid,
        projectid: this.editprojectid,
        wssid: this.editwssid,
        status: this.editstatus
      })
      this.$http.post('/wts/edit/wss_project', data)
      .then(response => {
        if (response.status === 200) {
          this.$toasted.show('Assignment Edited', {icon: 'fa-check', type: 'success', duration: '3000'})
          this.$refs.editassignmentmodal.hide();
        }
      })
      .catch(error => {
        this.editassignerror = true;
      })
      return;
    },
    sendInfo(id) {
      var val = id;
      let data = this.wssData;
      var id = data.findIndex(function(item, i){
        return item.wssid === val
      });
      this.id = data[id].wssid;
    },
    sendEditInfo(id) {
      var val = id;
      let data = this.wtsFilteredData;
      var id = data.findIndex(function(item, i){
        return item.wpid === val
      });

      this.editid = this.wtsFilteredData[id].wpid;
      this.editprojectid = this.wtsFilteredData[id].projectid;
      this.editwssid = this.wtsFilteredData[id].wssid;
      this.editstatus = this.wtsFilteredData[id].status;
    },
    findAssignments(id) {
      this.$http.get('/wts/get/wss_project/' + 2)
      .then(response => {
        if (response.status === 200) {
          this.wtsAssignmentData = response.data.result;
          this.wtsFilteredData = this.wtsAssignmentData.filter(o => o.wtsid === id);
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    },
    clearErrors() {
      this.error = false;
      this.assignerror = false;
      this.editassignerror = false;
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    loadData() {
      this.$http.get('/wts/get/wss/')
      .then(response => {
        if (response.status === 200) {
          this.wssData = response.data.result;
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
      this.$http.get('/wts/get/projects')
      .then(response => {
        if (response.status === 200) {
          this.projectData = response.data.result;
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    }
  },
  mounted: function () {
    this.loadData();
  },
}
</script>
